import axios from "axios";
let user = JSON.parse(localStorage.getItem('user'));
export default axios.create({
  baseURL: "https://pms.solusoft.app/api",
  headers: {
    "Content-type": "application/json",
    // Adds the logged in user token to headers instead of using auth-header
    "x-access-token": user.accessToken
  }
});
